<template>
  <div class="sm:py-4">
    <div
      class="
        p-4
        sm:rounded sm:shadow sm:max-w-screen-md
        mx-auto
        bg-white
        leading-loose
      "
    >
      <h1>FAQ</h1>
      <later-faq>
        <template slot="question">
          If I schedule an email to be sent 10 years from now, how do I know
          that this site will still be around to send it?
        </template>
        <p>
          We have been around since 2006 and have no intention of ever stopping!
          At that time we might have predicted that email would have been
          replaced entirely by some new hot tech by {{ localDate.year }}, but it
          seems that it is still as popular as ever.
        </p>
      </later-faq>

      <later-faq>
        <template slot="question">
          What are the options for email delivery?
        </template>
        <p>
          You have three options for the delivery of your emails. No matter
          which option you chose, all emails will have your email address as the
          return address, so any reply from your recipients will come back to
          your inbox.
        </p>
        <ol>
          <li>
            The first option is to have your emails digitally signed by
            later.io. This is the option we use by default since it is the
            easiest to set up and is more likely to make it through to your
            recipient's inbox. Using this option also has the added benefit of
            receiving individual recipient responses. For example, if you send
            one email with five recipients, you will be able to see the logged
            response (or lack thereof) of each of those recipients. The downside
            to this method is that some recipients may see a subtle message,
            such as "via later.io", which some people may not like. It is
            important for emails to be digitally signed, because this verifies
            the identity of the sender and without it, emails will get a higher
            likelihood of being flagged as spam. Another added benefit of this
            method is that we will receive a log message from each recipient
            whether or not the message was received.
          </li>
          <li>
            The second option is anonymous sending (unsigned), which has the
            previously mentioned downside of being more likely to get flagged as
            spam and no delivery confirmations.
          </li>
          <li>
            The third option is to have your emails sent via SMTP. This allows
            your emails to be sent by your email service, (or any email service
            for that matter). This is the best for option for not getting your
            emails flagged as spam, and has the added benefit of being able to
            see your sent emails in your sent folder. This option can be a
            little tricky to get set up initially, but totally worth it if you
            do. See the next section for more details.
          </li>
        </ol>
      </later-faq>

      <later-faq>
        <template slot="question">
          What is SMTP, and how is it used with later.io?
        </template>
        <p>
          SMTP (Simple Mail Transfer Protocol) is a means by which you can
          access your email provider's functions externally, including the
          sending of emails. Most all email providers these days support SMTP
          access. This can be configured on the
          <router-link to="/account/email"> account settings </router-link> page
          where it says, "Emails delivered by". By default your emails will be
          delivered by later.io. To change this, click the button to edit, then
          change the sending domain. The configuration details should be
          provided by your provider, providing they support SMTP (if that makes
          sense). For the most popular email providers, there will be links on
          the righthand sidebar to help you to enable this on your email
          account. For all others, you can simply do a web search for something
          like "yahoo smtp access" but instead of yahoo use whatever email
          provider you have.
        </p>
        <p>
          Like all decisions in life, there are pros and cons to each. Here is
          what we think of this particular decision.
        </p>
        <p>Pros of enabling SMTP access</p>
        <ul>
          <li>
            You'll get better delivery in some cases (meaning your emails are
            more likely to land in your recipients' inbox)
          </li>
          <li>
            Your later.io emails will appear in <i>your</i> email account's
            "sent" folder.
          </li>
          <li>
            Your recipients will not see that the email was sent via later.io.
          </li>
        </ul>
        <p>Cons of enabling SMTP access</p>
        <ul>
          <li>
            Added complexity. It takes a few minutes to get it configured,
            sometimes longer if the information is hard to find. Don't hesitate
            to <router-link to="/contact-us"> contact us </router-link> if you
            need help. And admittedly, it is a bit of mind bender to understand
            that you are using a service (later.io) to send your emails on
            behalf of your email account, but in fact your email provider is
            doing the sending, all while being completely seamless to your
            recipients, as if you sending the emails yourself at the delivery
            date/time. But then again, this is a website for the visionary,
            future thinking individuals of our time.
          </li>
          <li>
            Privacy concerns. Configuring this gives later.io access to your
            email account. We have a secure site with a track record of having
            no security breaches, and we never use the access for anything other
            than the intended purpose of sending the emails you write at the
            exact moment you chose. To help mitigate this concern, most email
            providers that allow SMTP access also provide (some even require it)
            what is generally known as "application specific passwords". This
            means you can create a password just for later.io, instead of using
            your main password. This has two additional benefits. One is that
            you can audit all access by later.io and make sure we are only using
            it for the intended purpose. And the second is if you change your
            main email password, you won't need to remember to come to later.io
            and update the password here as well.
          </li>
        </ul>
      </later-faq>

      <later-faq>
        <template slot="question">
          How do I know that my email was delivered?
        </template>
        <p>
          If your emails are delivered signed via Later.io, then you can see the
          response we received from your recipients' email provider by going to
          the <router-link to="/email/logs"> logs </router-link> page. Success
          means that the recipient accepted the email. A warning means a
          temporary failure, and we will try to send it again later. Red means
          that the email was not accepted. Each email provider creates their own
          messages, so hopefully it is something clear and easy to understand!
        </p>
        <p>
          If your emails are delivered by any other method, you usually will get
          a failure reply sent back to your email inbox, and no news if the
          email was delivered successfully.
        </p>
        <p>
          The top reason emails are rejected is because the mailbox no longer
          exists, or never existed. In this case, the solution is to check the
          address and try again.
        </p>
        <p>
          The next most common reason an email was blocked because it looked
          like spam. Everyone has their own process for determining an incoming
          email's "spaminess", and if it meets a certain threshold, it gets
          rejected. Spam filtering is unfortunately a very necessary thing, it's
          also an always moving target. An open source tool called DMARC
          (Domain-based Message Authentication, Reporting & Conformance) has
          been implemented by a few providers (Yahoo and AOL are the main ones).
          It basically means that if an email comes from a domain that is
          different than the sending address (for example an email that has an
          @yahoo.com email address but delivered through later.io), then the
          spam score is increased, and therefore the likelihood of the email
          getting blocked as spam is increased. If you are seeing failed emails
          due to spam filter issues, don't worry, there is a solution! (Unless
          of course you are sending spam, then your only choice is to stop
          immediately and rethink your life choices.) For everyone else, see the
          previous section for details on using SMTP.
        </p>
      </later-faq>

      <later-faq>
        <template slot="question">
          I've changed my mind about sending an email I've written. How do I
          make sure it does not get sent?
        </template>
        <p>
          This is the beauty of writing to the future! It gives you all the time
          you need to ponder the message you have written to make sure you get
          it just right. There are three ways to do it. If you delete the email,
          it will not be sent (even if it is in the trash and not deleted
          entirely). If you'd like to think about it for a while, change the
          status to "draft" it will not be sent until you are ready. And last,
          you can change the send date to the very distant future while you work
          writing the perfect email.
        </p>
      </later-faq>

      <later-faq>
        <template slot="question">
          What timezone are the dates represented in?
        </template>
        <p>
          Why your own timezone of course! We have detected
          <strong
            >{{ localDate.offsetNameLong }} ({{
              localDate.offsetNameShort
            }})</strong
          >
          from your browser. If that is not right, check your computer's
          settings. You can also access the date and timezone information by
          clicking on the date and time at the bottom of the page on the left
          sidebar. If you ever log in from a different timezone, all the times
          shown will reflect the new timezone automatically.
        </p>
      </later-faq>

      <later-faq>
        <template slot="question">
          If I want to send just one email ten years from now, do I need a
          subscription for all ten years?
        </template>
        <p>
          Nope, the subscription is necessary for when emails are composed, not
          delivered. Any and all of your emails that have been set to scheduled
          status will be delivered, regardless of subscription status.
        </p>
      </later-faq>

      <later-faq>
        <template slot="question"> Do you offer refunds? </template>
        <p>
          We offer a generous free forever plan that will allow you to test the
          site out and make sure that it will fit your needs before spending any
          money. All sales are final.
        </p>
      </later-faq>

      <later-faq>
        <template slot="question">
          I have a disabled contact, how did that happen, and how do I fix it?
        </template>
        <p>
          If we get a response from a recipient that says the delivery was
          rejected, we'll disable that contact (meaning any messages addressed
          to this contact will not be attempted). Reasons delivery might be
          rejected include, but are not limited to: mailbox does not exist,
          recipient marked your message as spam, emails are not accepted from
          outside sources (i.e. DMARC violation).
        </p>
        <p>
          You may re-enable disabled contacts on the "Contacts / Groups" page,
          but be aware that if your bounce rate (invalid email addresses, spam
          complaints) reaches a certain threshold, your account could be
          suspended.
        </p>
      </later-faq>

      <later-faq>
        <template slot="question">
          How do I close my later.io account?
        </template>
        <p>
          If you have decided this website is not for you, you can delete all
          the information permanently. First delete the emails and empty the
          trash. Then delete all your contacts. Then on the account settings
          page, delete all the email addresses you have associated with your
          account. When you have deleted them all, you will be have shut down
          your account.
        </p>
      </later-faq>

      <later-faq>
        <template slot="question">
          How do I schedule emails from my own email client?
        </template>
        <p>
          Yes it's true, you can use later.io without even having to come to
          this website!
        </p>
        <p>Here are the steps:</p>
        <ol>
          <li>
            Compose an email that you would like to be delivered at a later date
            (or dates) like you normally would. It can even be reply or a
            forward.
          </li>
          <li>
            Address the email to
            <a :href="`mailto:${what}later.io`" v-text="`${what}later.io`" />
          </li>
          <li>
            <p>
              Add the recipients, and time to send at the top of the message.
              (This text will be removed from the email) Here is what you need
              to include:
            </p>

            <div class="bg-black text-white text-sm p-2 mb-4 font-mono rounded">
              to: [recipient(s)]<br />
              cc: [recipient(s)]<br />
              bcc: [recipient(s)]<br />
              when: [date(s) - semicolon delimited for recurring emails]
            </div>

            <p>*At least one recipient and one send date is required.</p>

            <p>
              You will immediately receive a response, indicating whether is was
              done successfully or not. If it was, you will be able to see the
              email in your Later.io account.
            </p>
          </li>
        </ol>

        <p>For example, if you send this email:</p>
        <img src="@/img/faq/send-to-me-at-later.png" class="mb-12" />

        <p>You'll get an immediate response like this:</p>
        <img src="@/img/faq/response-from-me-at-later.png" class="mb-12" />

        <p><strong>Tips</strong></p>
        <ul>
          <li>
            The date format is flexible! You can either enter a time relative to
            the current time, or an absolute time. Some valid dates are listed
            here:
          </li>
          <ul class="list-none" style="list-style-type: none">
            <strong>now</strong
            ><br />
            <strong>next friday</strong
            ><br />
            <strong>next year</strong
            ><br />
            <strong>2pm</strong
            ><br />
            <strong>tomorrow 4:02 am</strong
            ><br />
            <strong>4 hours</strong
            ><br />
            <strong>45 minutes</strong
            ><br />
            <strong>03/15/2025 10:10am</strong
            ><br />
            <strong>Sunday January 1, 2012 at 6:00 AM</strong
            ><br />
          </ul>

          <li>
            Recipients can either be email addresses, or groups names. Each
            entry must be separated by a comma(,) or a semi-colon(;). Also you
            can put multiple entries on separate lines, for example:<br /><br />

            <div class="bg-black text-white text-sm p-2 mb-4 font-mono rounded">
              to: guy1@asdf.net<br />
              to: guy2@asdf.net<br />
              when: next week<br />
              when: 8 weeks<br />
            </div>
            You will need to do it this way if your email client has word-wrap,
            and your lines are long enough to get wrapped.
          </li>
          <li>
            Creating groups will greatly simplify addressing emails. For example
            you can even create a group called 'me', with your email address as
            the only contact in that group. Then when sending yourself a
            reminder, all you need to write is: <strong>to: me</strong>
          </li>
          <li>
            Email responses are not always instantaneous. They usually arrive in
            seconds, but at times could take a bit longer.
          </li>
        </ul>
      </later-faq>
    </div>
  </div>
</template>

<script>
import LaterFaq from '@/components/faq.vue';
import { DateTime } from 'luxon';

export default {
  components: {
    LaterFaq,
  },
  data: () => ({
    what: 'me@',
    localDate: DateTime.local(),
  }),
};
</script>
